.features-container {
    background-image: url("../../../../Assets/Images/Home/woman-fade-mob.jpg");
    background-size: cover;
    //width: 100%;
    background-position: 13%;

    @media screen and (min-width: 865px) {
        background-image: url("../../../../Assets/Images/Home/woman-fade.jpg");
    }

    @media screen and (min-width: 992px) {
        //width: 50%;
        background-position: unset;
    }
}

.feature-col {
    height: 100%;

    @media screen and (min-width: 576px) {
        padding: 2rem;
    }

    @media screen and (min-width: 865px) {
        padding: 0;
        width: 50%;
        margin-left: auto;
    }

    @media screen and (min-width: 992px) {
        width: 50%;
    }

    @media screen and (min-width: 1200px) {
        width: 50%;
    }
}

.features {
    padding: 4rem 0 4rem 0rem;

    @media screen and (min-width: 576px) {}

    @media screen and (min-width: 768px) {
        padding: 4rem 0 4rem 2rem;
    }

    @media screen and (min-width: 992px) {}
}