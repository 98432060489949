.video-container {
    padding: 6rem 0 10rem;
    margin-bottom: 10rem;
    background-color: #f7f7f7;

    @media screen and (min-width: 922px) {
        padding: 6rem 0 15rem;
    }

    .video-section {
        position: relative;
        .float {
            position: absolute;
            left: 50%;
            right: 50%;
        }
        .video-wrapper {
            display: flex;
            justify-content: center;
            margin: auto;

            video {
                width: 80vw;
                height: 100%;
                border-radius: 3px;

                @media screen and (min-width: 560px) {
                    width: 50vw;
                }

                @media screen and (min-width: 922px) {
                    width: 40vw;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}
