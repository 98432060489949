.course-toggle {
    padding: 5rem 0 1rem 0;
}

.toggle-courses {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
    .course-box {
        text-align: center;
        font-weight: bold;
        padding: 1rem;
        margin: 0 2rem;
        width: 250px;
        border-radius: 9px;
        border-top-left-radius: 28px;
        color: black;
        box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);
        border: 2px solid var(--primary-orange);
        cursor: pointer;
        background-color: unset;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.05);
            transition: all 0.5s ease;
        }
    }

    .selected {
        color: white;
        background-color: var(--primary-orange);
        transition: all 0.5s ease;
    }
}
