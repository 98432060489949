.scroll-container {
}

.scroll-banner {
    display: flex;
    overflow-x: auto;
    padding: 3rem 0 2em 0;

    img {
        margin: 0 1.5rem;
        max-width: 100px;
        max-height: 50px;
        align-self: center;
        //filter: grayscale(100%);
    }
}

@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

@media screen and (min-width: 576px) {
    .scroll-banner {
        padding: 3rem 0 2em 0;

        img {
            margin: 0 4.5rem;
            max-width: 125px;
            max-height: 75px;
            align-self: center;
            //filter: grayscale(95%);
            cursor: pointer;
            transition: all 0.2s ease-in-out 0s;

            // &:hover {
            //     filter: grayscale(0%);
            //     -webkit-transform: scale(1.05, 1.05);
            //     -moz-transform: scale(1.05, 1.05);
            //     -ms-transform: scale(1.05, 1.05);
            //     -o-transform: scale(1.05, 1.05);
            // }
        }
    }
}

.scroll-banner::-webkit-scrollbar {
    display: none;
}
