.why-us-container {
  ///background-color: #fafafa;
  height: 100%;

  @media screen and (min-width: 576px) {
    //height: 30vh;
  }
}

.why-us {
  position: absolute;
  top: -50px;
  right: 0;
  left: 0;

  @media screen and (min-width: 576px) {
    display: flex;
    justify-content: space-evenly;
  }

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
  }

  .icon-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
    width: 80%;
    padding: 0 10px;
    background-color: #fff;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 25px;
    -webkit-box-shadow: 0 1px 15px -2px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0 1px 15px -2px #ccc; /* Firefox 3.5 - 3.6 */
    box-shadow: 0 1px 15px -2px #ccc;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 576px) {
      width: 30%;
      margin-bottom: 0;
      -webkit-box-shadow: 0 7px 16px -6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow: 0 7px 16px -6px #ccc; /* Firefox 3.5 - 3.6 */
      box-shadow: 0 7px 16px -6px #ccc;
    }

    @media screen and (min-width: 768px) {
      width: 29%;
      max-width: 260px;
    }

    @media screen and (min-width: 1000px) {
      margin: 0 3rem;
    }
  }

  .icon-circle {
    display: inline-block;
    background-color: var(--primary-orange);
    font-size: 27px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    vertical-align: middle;
    border: 2px solid #fff;
  }
}
