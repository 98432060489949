.mobMenu {
    height: -webkit-fill-available;
    height: 100%;
    background-color: #373737;
    z-index: 1031;
    width: 80%;
    position: fixed;
    top: 0;
    left: -80%;
    overflow-x: hidden;
    padding-top: 30px;
    transition: 0.5s;

    .menu-links {
        ol {
            padding: 0;
        }
        li {
            list-style: none;
            &:hover {
                background-color: var(--primary-grey);
            }
        }

        a {
            position: relative;
            margin: auto;
            text-align: center;
            padding: 13px 8px;
            text-decoration: none;
            font-size: 18px;
            color: var(--primary-light-grey);
            display: block;
            transition: 0.5s;
            font-family: "Poppins-Regular";
        }

        i {
            font-size: 12px;
            color: white;
            position: absolute;
            right: 15px;
            margin: 6px 15px 0 0;
        }
    }

    .logo {
        display: flex;
        justify-content: center;
        padding: 1.5rem 0 3rem 0;
        width: 100%;

        img {
            width: 200px;
            height: 60px;
        }
    }
}

.open {
    left: 0;
}

@media screen and (min-width: 992px) {
    .mobMenu {
        display: none !important;
    }
}
