.btn {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.5rem 3rem;
    border-radius: 0.2rem;
    border-top-left-radius: 1rem;
    border: 0.1rem solid transparent;
}

.btn-outline-primary {
    transition: all 0.5s ease;
    border-color: var(--primary-orange);
}

.btn-outline-primary:hover {
    color: white;
}

.btn-outline-invert {
    transition: all 0.5s ease;
    border-color: white;
    color: white;
}

.btn-outline-invert:hover {
    color: white;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: unset;
}

.full-btn {
    background-color: var(--primary-orange);
    color: white;

    &:hover {
        background-color: white;
        color: var(--primary-orange);
    }
}

.ac-btn {
    display: block;
    padding: 0.85rem;
    margin: 0;
    max-width: 500px;
    width: 250px;
    border-radius: 9px;
    border-top-left-radius: 28px;
    color: black;
    box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);
    border: 2px solid var(--primary-orange);
    cursor: pointer;
    background-color: white;
    transition: all 0.5s ease;

    @media screen and (max-width: 768px) {
        margin: auto !important;
    }

    div {
        text-align: center;
        font-weight: bold;
    }

    &:hover {
        transform: scale(1.05);
        transition: all 0.5s ease;
        text-decoration: none;
        color: black;
    }
}

.ac-btn-full {
    display: block;
    padding: 0.85rem;
    margin: 0;
    max-width: 500px;
    width: 250px;
    border-radius: 9px;
    border-top-left-radius: 28px;
    color: white;
    box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);
    background-color: var(--primary-orange);
    border: 2px solid var(--primary-orange);
    cursor: pointer;
    transition: all 0.5s ease;
    @media screen and (max-width: 768px) {
        margin: auto !important;
    }

    div {
        text-align: center;
        font-weight: bold;
    }

    &:hover {
        transform: scale(1.05);
        transition: all 0.5s ease;
        text-decoration: none;
        color: white;
    }
}
