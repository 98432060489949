.partners:nth-child(odd) {
    background-color: var(--background-grey);
}

.partners {
    padding: 6rem 0;

    @media screen and (min-width: 768px) {
        padding: 7rem 0;
    }

    .row-reverse {
        flex-direction: row-reverse;

        .reverse {
            @media screen and (min-width: 768px) {
                padding-left: 60px;
            }
            @media screen and (min-width: 922px) {
                padding-left: 80px;
            }
        }

        @media screen and (min-width: 768px) {
            .text {
                text-align: right !important;
            }
        }

        .text,
        .line-row {
            text-align: center !important;

            @media screen and (min-width: 768px) {
                text-align: left !important;
            }
        }
    }

    .partners-image-container {
        .text {
            text-align: center;
            h2 {
                margin-bottom: 1.5rem;
            }

            @media screen and (min-width: 768px) {
                text-align: left;
            }
        }

        img {
            max-width: 80%;
            border-radius: 12px;
            margin-top: 2rem;
        }

        @media screen and (min-width: 350px) {
            img {
                max-width: 60%;
            }
        }

        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;

            img {
                max-width: 295px;
                margin-top: 0;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .intro-container {
        img {
            width: 350px;
        }
    }
}

.no-border-img {
    img {
        box-shadow: none !important;
        max-height: 350px;
        max-width: 85%;
        width: auto;
    }
}

.image-top {
    display: flex;
    flex-flow: column-reverse;
}
