:root {
  --primary-orange: #f38621;
  --primary-orange-accent: #f4a55b;
  --primary-light-grey: #f0f0f0;
  --primary-grey: #717171;
  --primary-black: #0f0f0f;
  --background-grey: #f7f7f7;
  --error-red: #f12e2e;

  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

$orange: var(--primary-orange);

$theme-colors: (
  "primary": #f38621,
  "danger": #ff4136,
);
