.course-item {
    background-color: #f7f7f7;
    width: 100%;
    border-radius: 9px;
    border-top-left-radius: 28px;
    box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        .overlay-desktop {
            transform: translateY(-70px);
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
    }

    img {
        border-top-left-radius: 28px;
        background-size: cover;
        object-fit: cover;
        width: 100%;
        max-height: 315px;

        @media screen and (max-width: 767px) {
            border-top-right-radius: 9px;
            object-position: top;
        }

        @media screen and (min-width: 768px) {
            border-bottom-left-radius: 9px;
            max-width: 188px !important;
        }
        @media screen and (min-width: 992px) {
            max-width: 280px !important;
        }

        @media screen and (min-width: 1200px) {
            //max-height: 180px;
        }
    }

    .course-info {
        position: relative;
        padding: 25px;

        @media screen and (min-width: 767px) {
            padding: 25px 15px 0 15px;
            display: flex;
            flex-direction: column;
        }

        .info-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            h5 {
                margin-bottom: 1rem;
            }

            p {
                font-size: 0.81rem;
                margin-bottom: 0;
            }

            .course-length {
                padding-top: 1rem;
                .icon {
                    color: var(--primary-orange);
                }

                @media screen and (min-width: 767px) {
                    right: 25px;
                    bottom: 0;
                    padding-bottom: 15px;
                }
                span {
                    font-size: 0.85rem;
                    text-align: right;
                    font-weight: 500;
                    width: 100%;
                    color: var(--primary-orange);
                }
            }
        }
    }
}

.overlay-desktop {
    img {
        display: none;
    }
    @media screen and (min-width: 768px) {
        img {
            display: unset;
            width: 130px;
            height: 130px;
            border-radius: 0;
        }
        position: absolute;
        bottom: -82px;
        left: 15px;
        cursor: pointer;
        transition: all 0.3s linear;
    }
}

.overlay-mobile {
    img {
        position: absolute;
        width: 130px;
        height: 130px;
        border-radius: 0;
        top: -130px;
        left: 10px;

        @media screen and (max-width: 376px) {
            width: 100px;
            top: -100px;
        }
    }

    @media screen and (min-width: 768px) {
        img {
            display: none;
        }
    }
}
