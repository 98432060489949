:root {
    @keyframes fadeInUp {
        from {
            transform: translate3d(0, 40px, 0);
        }

        to {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeInUp {
        from {
            transform: translate3d(0, 40px, 0);
        }

        to {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes fadeInDelay {
        from {
            //transform: translate3d(0, 40px, 0);
        }

        to {
            //transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeInDelay {
        from {
            //transform: translate3d(0, 40px, 0);
        }

        to {
            // transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes slideinLeft {
        from {
            transform: translateX(-50%);
            opacity: 0;
        }
        to {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideinRight {
        from {
            transform: translateX(50%);
            opacity: 0;
        }
        to {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

//Fade
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    opacity: 0;
}

//Fade Up works with .animated
.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.fadeInUpDelay {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    animation-delay: 1.2s;
}

.fadeInUpDelayFast {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    animation-delay: 250ms;
}

.fadeInDelay {
    opacity: 0;
    animation-name: fadeInDelay;
    -webkit-animation-name: fadeInDelay;
    animation-delay: 500ms;
}

.fadeInDelayFast {
    opacity: 0;
    animation-name: fadeInDelay;
    -webkit-animation-name: fadeInDelay;
    animation-delay: 50ms;
}

//Fade in
.fade-in {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.fade-in.appear {
    opacity: 1;
}

.slide-left {
    float: right;
    animation-duration: 1.2s;
    animation-name: slideinLeft;
}

.slide-right {
    float: left;
    animation-duration: 1.2s;
    animation-name: slideinRight;
}
