// Orange Line with Text
@mixin line-a {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 0.4rem;
    text-transform: uppercase;
    color: #3a3a3a;
    font-weight: bold;
    text-align: center;
}

@mixin line {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 30px;
    height: 0.1rem;
    content: "\a0";
    background-color: var(--primary-orange);
}

//mobile
.line-row {
    @include line-a;

    span {
        margin-left: 0;
        font-size: 0.7rem;
        line-height: 26px;

        @media screen and (min-width: 768px) {
            font-size: 0.8rem;
            margin-left: 45px;
        }
    }
}

.line-row:before,
.line-row:after {
    @include line;
}
.line-row:before {
    text-align: center;
    margin-left: -45px;
}
.line-row:after {
    margin-left: 15px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .reverse .line-row {
        text-align: right !important;
    }
}
//desktop etc
@media screen and (min-width: 768px) {
    .line-row {
        @include line-a;
        text-align: left;
    }

    .line-row:before {
        @include line;
    }
    .line-row:before {
        text-align: right;
        margin-left: -45px;

        margin-left: 0;
    }

    .line-row:after {
        content: none;
    }
}

.line-center {
    @include line-a;

    span {
        margin-left: 0;
        font-size: 0.8rem;
        line-height: 26px;
    }
}

.line-center:before,
.line-center:after {
    @include line;
}
.line-center:before {
    text-align: center;
    margin-left: -45px;
}
.line-center:after {
    margin-left: 15px;
    text-align: center;
}
