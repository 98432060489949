.ac-testimonials {
    position: relative;
    height: 100%;
    margin: 2.5rem 0;
    padding: 2rem;
    background: #ffffffba;
    border-radius: 1rem;
    border: 0;
    box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);
    display: flex;
    align-items: center;

    .ac-content {
        justify-content: center;

        @media screen and (min-width: 521px) {
            padding: 0 3rem;
        }

        @media screen and (min-width: 1000px) {
            padding: 0 5rem;
        }
    }

    p {
        font-size: 13px;
    }

    .role {
        margin-bottom: 4px;
    }

    .role,
    .company {
        font-size: 13px;
        font-style: italic;
    }

    button:focus {
        outline: none !important;
    }
}

.ac-testimonials h5 {
    color: var(--primary-orange);
    line-height: 1.3;
    margin-bottom: 1rem;
}
.ac-testimonials h5 span {
    color: #666666;
}

.ac-testimonials .testimonial-body {
    padding: 1rem 2.5rem 2.5rem 2rem;
}

.ac-testimonials .card-text {
    color: #555;
    font-size: 13px;
    padding-top: 1rem;
    font-weight: bold;
}

.ac-testimonials .active {
    opacity: 0.5;
    transition: all 0.3s;
}

.ac-testimonials .center .card-img-top {
    max-width: 100%;
    //height: 4rem;
    width: 4rem;
}

.testimonial-image {
    vertical-align: middle;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 10px 5px 15px 5px;
    box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);

    @media screen and (min-width: 769px) {
        width: 100px;
        height: 100px;
    }
}

.awssld__content {
    background-color: unset !important;
    padding: 0px 5px;
}

.awssld__bullets button {
    height: 8px !important;
    width: 8px !important;
    background: var(--primary-orange) !important;
}

.awssld__timer {
    //display: none !important;
    height: 0 !important;
}

.awssld__container {
    height: 690px !important;

    @media screen and (min-width: 400px) {
        height: 600px !important;
    }

    @media screen and (min-width: 525px) {
        height: 580px !important;
    }

    @media screen and (min-width: 768px) {
        height: 465px !important;
    }

    //height: unset !important;
    padding-bottom: unset !important;
}
