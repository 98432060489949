.image-row {
    .section-img {
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 39%;
        width: 100%;
        min-height: 500px;
        padding: 5rem 0;
    }

    .center-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-row,
    .image-row-text {
        width: 100%;
        color: white;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: relative;
        h2 {
            color: white;
        }
    }

    .image-row-text {
        padding: 4rem 0 0 0;
    }
}
