.methods {
    text-align: center;

    @media screen and (min-width: 768px) {
        padding: 3rem 0 3rem 0;
    }

    .method-item {
        padding: 2rem;
        p {
            max-width: 250px;
            margin: auto;
        }
        @media screen and (min-width: 768px) {
            padding: 0;
        }
    }

    h5 {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
    }

    a {
        margin-top: 0;
        margin-bottom: 0 !important;
        //font-size: 13px;
        font-weight: bold;
        color: var(--primary-orange);
        display: block;
    }

    a:hover {
        color: var(--primary-grey) !important;
        text-decoration: none !important;
    }

    p {
        font-size: 12px;
        margin: 0;
    }

    i {
        font-size: 26px;
        color: var(--primary-orange);
        padding: 10px 0 20px 0;
    }
}
