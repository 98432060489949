.hero {
  height: 77vh;
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: 77vh;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0.75;

    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 766px) {
      background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255), transparent);
      width: 100%;
    }
    @media screen and (min-width: 992px) {
      width: 65%;
    }
    @media screen and (min-width: 1200px) {
      width: 65%;
    }
  }
  .hero-img {
    // background-image: url(../../../Assets/Images/Home/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 65% 57%;
    width: 100%;

    @media screen and (min-width: 576px) {
      background-position: 0% 57%;
    }
    @media screen and (min-width: 576px) {
      background-position: 30% 57%;
    }
  }

  @-webkit-keyframes slide {
    100% {
      left: 50%;
    }
  }

  @keyframes slide {
    100% {
      left: 50%;
    }
  }

  .hero-text {
    border-radius: 10px;
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 0 33px;
    left: -20%;
    top: 49%;
    transform: translate(-50%, -50%);
    animation: slide 1.5s forwards;
    animation-delay: 500ms;

    @media screen and (min-width: 768px) {
      padding-left: 3rem;
      text-align: left;
    }
    @media screen and (min-width: 922px) {
      padding-left: 7.5rem;
    }

    h1 {
      font-size: 1.6rem;
      @media screen and (max-width: 420px) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: 576px) {
        font-size: 1.8rem;
      }

      @media screen and (min-width: 768px) {
        font-size: 2.1rem;
      }

      @media screen and (min-width: 922px) {
        font-size: 2.2rem;
      }
    }

    p {
      padding-top: 10px;
      // color: var(--primary-grey);
      color: black;
      font-weight: 600;
      font-size: 15px;

      @media screen and (min-width: 768px) {
        width: 70%;
      }

      @media screen and (min-width: 992px) {
        width: 50%;
      }
    }
  }
}
